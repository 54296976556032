import React from 'react';
import { navigate } from "gatsby";
import './Stores.scss';
import Icon from "../../assets/images/icons/har-fins-vi.svg";

const Stores = () => {
	const handleFirstCityClick = () => {
		localStorage.setItem('city', 'jonkoping');
		navigate('/');
	}

	const handleSecondCityClick = () => {
		localStorage.setItem('city', 'linkoping');
		navigate('/linkoping/');
	}

	// const scrollToCities = () => {
	// 	const cities = document.querySelector('.list');

	// 	cities.scrollIntoView({ behavior: "smooth", block: "center" });
	// }

	return (
		<div className="Stores max-w-7xl mx-auto">
			<div className="top">
				<div className="left">
					<div className="heading">Här finns Dreamclean nära dig</div>
					<div className="description">Välkommen till ett Dreamclean nära dig. Vi erbjuder städtjänster på olika orter. Klicka på våra lokala regionkontor nedan för att läsa mer om städning på din ort.</div>
					{/* <button className="stores-btn" onClick={scrollToCities}>Här finns vi</button> */}
				</div>
				<div className="right">
					<Icon />
				</div>
			</div>
			<div className="list">
				<div className="list-item">
					<div class="name">
						<h3>Jönköping</h3>
					</div>
					<div className="info">
						<a href="tel:+4636141500">036 - 14 15 00</a>
						<a href="mailto:info@dreamclean.nu">info@dreamclean.nu</a>
						<p>Huskvarnavägen 62</p>
						<p>554 54 Jönköping</p>
					</div>
					<div className="buttons">
						<button className="stores-btn" onClick={handleFirstCityClick}>Till Kontorssidan</button>
					</div>
				</div>

				<div className="list-item">
					<div class="name">
						<h3>Linköping</h3>
					</div>
					<div className="info">
						<a href="tel:+4636141500">013 - 15 16 00</a>
						<a href="mailto:info@dreamclean.nu">linkoping@dreamclean.nu</a>
						<p>Gamla Tanneforsvägen 90</p>
						<p>582 54 Linköping</p>
					</div>
					<div className="buttons">
						<button className="stores-btn" onClick={handleSecondCityClick}>Till Kontorssidan</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Stores;